import React, { useState } from "react";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import NavbarCareer from "../components/NavbarCareer";
import FloatingButton from "../components/Floatingbutton";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function CareerApply() {

  const [formStep, setFormStep] = useState(1);
  const [inputs, setInputs] = useState({
      fullName: '',
      dob: '',
      email: '',
      mobile: '',
      gender: '',
      cnic: '',
      domicile: '',
      province: '',
      fathermobile: '',
      matricTotalMarks: '',
      matricObtainedMarks: '',
      matricPassingYear: '',
      address: '',
      intermediateTotalMarks: '',
      intermediateObtainedMarks: '',
      district: '',
      intermediatePassingYear: '',
      universitySemester: '',
      universityCGPA: '',
      Appliedfor: '',
 
  });
 const [selectedImage, setSelectedImage] = useState(null);
  const handleChange = (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
  };

  const handleNext = () => {
    if (formStep === 1) {
        const requiredFields = [
            'fullName', 
            'fathername',
            'dob', 
            'email', 
            'mobile', 
            'gender', 
            'cnic', 
            'domicile', 
            'province', 
            'district', 
            'address', 
            'fathermobile'
        ];

        const isValid = requiredFields.every(field => inputs[field]);

        if (isValid) {
            setFormStep(2);
        } else {
            alert('Please fill out all required fields in this step.');
        }
    }
  };

  const handleBack = () => {
      setFormStep(1);
  };


 

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file); // Set the image file for form submission
    }
  };

  const handleSubmit = async  (e) => {
    e.preventDefault();

    const formData = new FormData();
    
    // Append form inputs to FormData
    Object.keys(inputs).forEach((key) => {
      formData.append(key, inputs[key]);
    });

    // Append the image file
    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    // Debugging the form data content
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    // You can now send the formData via fetch or axios
    // For example, using fetch:
    try {
      const response = await axios.post('https://sit.backend.skans.pk/applicants', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    //   console.log("Form submitted successfully", response.data);
      toast.success('Application submitted successfully!', {
        position: "top-center", // Centered position
        autoClose: 3000, // Duration in milliseconds
        hideProgressBar: true, // Hide the progress bar
        closeOnClick: true, // Close on click
        pauseOnHover: true, // Pause on hover
        draggable: true, // Draggable
        theme: "light", // Theme (light or dark)
      });

      setInputs({
        fullName: '',
        dob: '',
        email: '',
        mobile: '',
        gender: '',
        cnic: '',
        domicile: '',
        province: '',
        fathermobile: '',
        matricTotalMarks: '',
        matricObtainedMarks: '',
        matricPassingYear: '',
        address: '',
        intermediateTotalMarks: '',
        intermediateObtainedMarks: '',
        district: '',
        intermediatePassingYear: '',
        universitySemester: '',
        universityCGPA: '',
        Appliedfor: '',
    });
    setSelectedImage(null);

    } catch (error) {
    //   console.error("Error submitting form", error);
      toast.error('Error submitting form!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  
  

  return (
    <>
    <NavbarCareer/>
    <FloatingButton/>
      <div className="container-form container">
          <header className="pt-5 fs-3">Personal Details</header>
          <form onSubmit={handleSubmit} className="container" enctype= "multipart/form-data">
              {formStep === 1 && (
                  <div className="form first">
                      <div className="details personal">
                          {/* <span className="title">Personal Details</span> */}
                          <div className="fields">
  
                          <div className="image-upload">
                            <label style={{ cursor: 'pointer' }} htmlFor="imageUpload">Upload your Photo</label>
                            <input
                            name="image"
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />
                        </div>

                        {/* Image Preview */}
                        
                            <div className="image-preview">
                            {selectedImage && (
                                <img
                                     src={URL.createObjectURL(selectedImage)}
                                    alt="Preview"
                                    className="preview-img"
                                /> 
                                )}

                            </div>
                       
        
                              <div className="input-field">
                                  <label>Full Name</label>
                                  <input type="text" name="fullName" placeholder="Enter your name" value={inputs.fullName} required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Father Name</label>
                                  <input type="text" name="fathername" placeholder="Enter Father Name" value={inputs.fathername} required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Date of Birth</label>
                                  <input type="date" name="dob" value={inputs.dob} required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>CNIC</label>
                                  <input type="number" name="cnic" value={inputs.cnic} placeholder="Enter your CNIC" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Email</label>
                                  <input type="text" name="email" value={inputs.email}placeholder="Enter your email" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Domicile</label>
                                  <input type="text" name="domicile" value={inputs.domicile} placeholder="Enter city" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Province</label>
                                  <input type="text" name="province" value={inputs.province} placeholder="Enter Province" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>District</label>
                                  <input type="text" name="district" value={inputs.district} placeholder="Enter District" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Address</label>
                                  <textarea rows="3" type="text" name="address" value={inputs.address} placeholder="Enter Address" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Mobile Number</label>
                                  <input type="number" name="mobile" value={inputs.mobile} placeholder="Enter mobile number" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Gender</label>
                                  <select name="gender" value={inputs.gender} required onChange={handleChange}>
                                      <option value="" disabled selected>Select gender</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                      <option value="Others">Others</option>
                                  </select>
                              </div>
                              <div className="input-field">
                                  <label>Father Mobile No.</label>
                                  <input type="text" name="fathermobile" value={inputs.fathermobile}placeholder="Enter Father Mobile No" required onChange={handleChange} />
                              </div>
                          </div>
                      </div>
                      {/* <div className="details ID">
                          <span className="title">Identity Details</span>
                          <div className="fields">
                              <div className="input-field">
                                  <label>ID Type</label>
                                  <input type="text" name="idType" placeholder="Enter ID type" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>ID Number</label>
                                  <input type="number" name="idNumber" placeholder="Enter ID number" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Issued Authority</label>
                                  <input type="text" name="issuedAuthority" placeholder="Enter issued authority" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Issued State</label>
                                  <input type="text" name="issuedState" placeholder="Enter issued state" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Issued Date</label>
                                  <input type="date" name="issuedDate" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Expiry Date</label>
                                  <input type="date" name="expiryDate" required onChange={handleChange} />
                              </div>
                          </div>

                      </div> */}                          
                      <button type="button" className="nextBtn" onClick={handleNext}>
                              <span className="btnText">Next</span>
                              <i className="uil uil-navigator"></i>
                          </button>
                  </div>
              )}

              {formStep === 2 && (
                  <div className="form first">
                      {/* <div className="details address">
                          <span className="title fs-3 fw-bold">Academic Records</span>
                          <div className="fields">
                              <div className="input-field">
                                  <h4>Qualification:</h4> 
                                  <input type="text" name="addressType" placeholder="Permanent or Temporary" required onChange={handleChange} />
                              </div>
                              <div className="input-field ">
                                  <label>Nationality</label>
                                  <input type="text" name="nationality" placeholder="Enter nationality" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>State</label>
                                  <input type="text" name="state" placeholder="Enter your state" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>District</label>
                                  <input type="text" name="district" placeholder="Enter your district" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Block Number</label>
                                  <input type="number" name="blockNumber" placeholder="Enter block number" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Ward Number</label>
                                  <input type="number" name="wardNumber" placeholder="Enter ward number" required onChange={handleChange} />
                              </div>
                          </div>
                      </div> */}
                      <div className="details academic">
    <h4>Academic Records</h4>
    <div className="fields">
      {/* Matric Qualification */}
      <div className="input-field">
        <label>Matric Total Marks</label>
        <input type="number" name="matricTotalMarks" value={inputs.matricTotalMarks} placeholder="Enter total marks" required onChange={handleChange} />
      </div>
      <div className="input-field">
        <label>Matric Obtained Marks</label>
        <input type="number" name="matricObtainedMarks" value={inputs.matricObtainedMarks} placeholder="Enter Obtained marks" required onChange={handleChange} />
      </div>
      <div className="input-field">
        <label>Matric Passing Year</label>
        <input type="month" name="matricPassingYear" value={inputs.matricPassingYear} placeholder="Enter passing year" required onChange={handleChange} />
      </div>

      {/* Intermediate Qualification */}
      <div className="input-field">
        <label>Intermediate Total Marks</label>
        <input type="number" name="intermediateTotalMarks" value={inputs.intermediateTotalMarks} placeholder="Enter total marks" required onChange={handleChange} />
      </div>
      <div className="input-field">
        <label>Intermediate Obtained Marks</label>
        <input type="number" name="intermediateObtainedMarks" value={inputs.intermediateObtainedMarks} placeholder="Enter Obtained marks" required onChange={handleChange} />
      </div>
      <div className="input-field">
        <label>Intermediate Passing Year</label>
        <input type="month" name="intermediatePassingYear" value={inputs.intermediatePassingYear} placeholder="Enter passing year" required onChange={handleChange} />
      </div>

      {/* University Qualification */}
      <div className="input-field">
        <label>University Semester</label>
        <input type="number" name="universitySemester" value={inputs.universitySemester} placeholder="Enter Semester" required onChange={handleChange} />
      </div>
      <div className="input-field">
        <label>University CGPA</label>
        <input type="number" step="0.01" name="universityCGPA" value={inputs.universityCGPA} placeholder="Enter CGPA" required onChange={handleChange} />
      </div>
      <div className="input-field">
                                  <label>Applied In</label>
                                  <select name="Appliedfor" value={inputs.Appliedfor} required onChange={handleChange}>
                                      <option value="" disabled selected>Select Program</option>
                                      <option value="Internship Program">Internship Program</option>
                                      <option value="DAE Mechanical">DAE Mechanical</option>
                                      <option value="DAE Civil">DAE Civil</option>
                                      <option value="DAE Electrical">DAE Electrical</option>
                                      <option value="DAE CIT">DAE CIT</option>
                                  </select>
                              </div>
    </div>
  </div>

                      {/* <div className="details family">
                          <span className="title">Family Details</span>
                          <div className="fields">
                              <div className="input-field">
                                  <label>Father Name</label>
                                  <input type="text" name="fatherName" placeholder="Enter father name" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Mother Name</label>
                                  <input type="text" name="motherName" placeholder="Enter mother name" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Grandfather</label>
                                  <input type="text" name="grandfather" placeholder="Enter grandfather name" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Spouse Name</label>
                                  <input type="text" name="spouseName" placeholder="Enter spouse name" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Father in Law</label>
                                  <input type="text" name="fatherInLaw" placeholder="Father in law name" required onChange={handleChange} />
                              </div>
                              <div className="input-field">
                                  <label>Mother in Law</label>
                                  <input type="text" name="motherInLaw" placeholder="Mother in law name" required onChange={handleChange} />
                              </div>
                          </div>
                          <div className="buttons">
                              <button type="button" className="backBtn" onClick={handleBack}>
                                  <i className="uil uil-navigator"></i>
                                  <span className="btnText">Back</span>
                              </button>
                              <button type="submit" className="submit">
                                  <span className="btnText">Submit</span>
                                  <i className="uil uil-navigator"></i>
                              </button>
                          </div>
                      </div> */}
                        <div className="buttons">
                              <button type="button" className="backBtn" onClick={handleBack}>
                                  <i className="uil uil-navigator"></i>
                                  <span className="btnText text-center">Back</span>
                              </button>
                              <button type="submit" className="submit">
                                  <span className="btnText text-center">Submit</span>
                                  <i className="uil uil-navigator"></i>
                              </button>
                          </div>
                  </div>
              )}
          </form>
      </div>
      <ToastContainer />
      <JoinUs/>
      <Footer/>
      </>
  );
}

export default CareerApply;
