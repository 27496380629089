import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import FloatingButton from '../components/Floatingbutton';
import Footer from '../components/Footer';
import ReactPaginate from 'react-paginate';
import '../CSS/Function.css';
import '../CSS/webstyle.css';

import JoinUs from '../components/JoinUs';


function Function() {
  const images = [
    { id: 1, src: require('../Photos/gallery1.jpg'), category: 'Orientation Day' },
    { id: 2, src: require('../Photos/gallery2.jpg'), category: 'Orientation Day' },
    { id: 3, src: require('../Photos/gallery7.jpg'), category: 'Orientation Day' },
    { id: 4, src: require('../Photos/gallery8.jpg'), category: 'Orientation Day' },
    { id: 5, src: require('../Photos/gallery9.jpg'), category: 'Orientation Day' },
    { id: 6, src: require('../Photos/gallery10.jpg'), category: 'Orientation Day' },
    { id: 7, src: require('../Photos/gallery11.jpeg'), category: 'Independence Day' },
    { id: 8, src: require('../Photos/gallery12.jpeg'), category: 'Independence Day' },
    { id: 9, src: require('../Photos/gallery13.jpeg'), category: 'Independence Day' },
    { id: 10, src: require('../Photos/gallery14.jpeg'), category: 'Seerat e Nabvi Conference' },
    { id: 11, src: require('../Photos/gallery15.jpeg'), category: 'Seerat e Nabvi Conference' },
    { id: 12, src: require('../Photos/gallery16.jpeg'), category: 'Seerat e Nabvi Conference' },
    { id: 13, src: require('../Photos/gallery17.jpeg'), category: 'Seerat e Nabvi Conference' },
    { id: 14, src: require('../Photos/gallery18.jpeg'), category: 'Seerat e Nabvi Conference' },
    { id: 15, src: require('../Photos/gallery19.jpeg'), category: 'Mechanical Students Visit NUST for Foundry Experiments' },
    { id: 16, src: require('../Photos/gallery20.jpeg'), category: 'Mechanical Students Visit NUST for Foundry Experiments' },
    { id: 17, src: require('../Photos/gallery21.jpeg'), category: 'Mechanical Students Visit NUST for Foundry Experiments' },
    { id: 18, src: require('../Photos/gallery22.jpeg'), category: 'Mechanical Students Visit NUST for Foundry Experiments' },
    { id: 19, src: require('../Photos/gallery23.jpeg'), category: 'Mechanical Students Visit NUST for Foundry Experiments' },

    // Add more images with categories...
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTab, setSelectedTab] = useState('All');
  const imagesPerPage = 6;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setCurrentPage(0); // Reset to first page on tab change
  };

  const filteredImages = selectedTab === 'All' 
    ? images 
    : images.filter(image => image.category === selectedTab);

  const sortedImages = filteredImages.sort((a, b) => b.id - a.id);

  const offset = currentPage * imagesPerPage;
  const currentImages = sortedImages.slice(offset, offset + imagesPerPage);

  return (
    <>
      <Navbar />
      <FloatingButton />
      <section className="section pt-3 mb-0 pb-0">
        <main className="hero-content">
          <div className="container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                    Skans Gallery Events: Where Every Moment Finds Its Timeless Frame
                  </h1>
                  <p className="regular-xl max-width-420">
                    At Skans Gallery Events, we curate more than just visuals; we craft stories that unfold with every click, capturing the essence of your unforgettable moments in exquisite detail.
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal Library-background"></div>
            </div>
          </div>
        </main>
      </section>
      <div className="container mt-5">
      <div className="tabs-menu w-tab-menu" role="tablist">
          <a 
            data-w-tab="All" 
            className={`tab-link w-inline-block w-tab-link ${selectedTab === 'All' ? 'w--current' : ''}`} 
            onClick={() => handleTabClick('All')} 
            role="tab" 
            aria-controls="w-tabs-0-data-w-pane-0"
          >
            <div className="regular-m">All</div>
          </a>
          <a 
            data-w-tab="Mechanical Students Visit NUST for Foundry Experiments" 
            className={`tab-link w-inline-block w-tab-link ${selectedTab === 'Mechanical Students Visit NUST for Foundry Experiments' ? 'w--current' : ''}`} 
            onClick={() => handleTabClick('Mechanical Students Visit NUST for Foundry Experiments')} 
            role="tab" 
            aria-controls="w-tabs-0-data-w-pane-1"
          >
            <div className="regular-m">Mechanical Students Visit NUST for Foundry Experiments</div>
          </a>
          <a 
            data-w-tab="Seerat e Nabvi Conference" 
            className={`tab-link w-inline-block w-tab-link ${selectedTab === 'Seerat e Nabvi Conference' ? 'w--current' : ''}`} 
            onClick={() => handleTabClick('Seerat e Nabvi Conference')} 
            role="tab" 
            aria-controls="w-tabs-0-data-w-pane-1"
          >
            <div className="regular-m">Seerat e Nabvi Conference</div>
          </a>
          <a 
            data-w-tab="Independence Day" 
            className={`tab-link w-inline-block w-tab-link ${selectedTab === 'Independence Day' ? 'w--current' : ''}`} 
            onClick={() => handleTabClick('Independence Day')} 
            role="tab" 
            aria-controls="w-tabs-0-data-w-pane-1"
          >
            <div className="regular-m">Independence Day 2024</div>
          </a>

          <a 
            data-w-tab="Orientation Day" 
            className={`tab-link w-inline-block w-tab-link ${selectedTab === 'Orientation Day' ? 'w--current' : ''}`} 
            onClick={() => handleTabClick('Orientation Day')} 
            role="tab" 
            aria-controls="w-tabs-0-data-w-pane-1"
          >
            <div className="regular-m">Orientation Day</div>
          </a>
          {/* Add more tabs here */}
        </div>
      </div>
      <main className="container mt-4">
        <div className="row">
          {currentImages.map((image) => (
            <div key={image.id} className="col-md-4 mb-4 " >
              {/* <a href={`/gallery/${image.id}`} className="d-block text-decoration-none"> */}
                {/* <div className=""> */}
                  <img
                  style={{objectFit: 'cover', width: '100%', height: '100%', maxHeight: '350px' ,borderRadius: '10px'}}
                    src={image.src}
                    loading="lazy"
                    alt={`Image ${image.id}`}
                    className="img-fluid"
                  />
                {/* </div> */}
                {/* <div className="mt-2">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="text-muted">{image.category}</div>
                    <div className="text-muted">{`Date`}</div>
                  </div>
                  <h4 className="h4">{`Title`}</h4>
                </div> */}
              {/* </a> */}
            </div>
          ))}
        </div>
      </main>
      <div className="pagination-wrapper mt-4">
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={Math.ceil(filteredImages.length / imagesPerPage)}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-numbers"}
          activeClassName={"current"}
          previousClassName={"page-numbers prev"}
          nextClassName={"page-numbers next"}
          disabledClassName={"disable"}
        />
      </div>
      <Footer />
    </>
  );
}

export default Function;
